import React, { Component } from 'react'

class Hero extends Component {
	render(){
		const { data, type='' } = this.props
		if(type==="preview"){
			return(
				<div
			    	className="full-width-image margin-top-0"
			      style={{
			        backgroundImage: `url(${ data.image })`,
			        backgroundPosition: `center center`,
			        backgroundSize: 'cover',
			        backgroundAttachment: `fixed`,
			        height: '700px',
			      }}
			    >
			      <div
			        style={{
			          display: 'flex',
			          height: '150px',
			          lineHeight: '1',
			          justifyContent: 'space-around',
			          alignItems: 'left',
			          flexDirection: 'column',
			          width: "90%",
			        }}
			      >
			        <div className="has-text-centered">
			          <img
			            style={{width: 150}}
			            src={data.midimage.image}
			            alt="midimage"
			          />
			        </div>
			        <div style={{height: 20, textAlign: `${data.sideimage.align}` }}>
			          <img
			            style={{width: 150, marginTop: "-80px" }}
			            src={data.sideimage.image}
			            alt="sideimage"
			          />
			        </div>
			        <h1
			          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
			          style={{
			            color: '#53c5d1',
			            lineHeight: '1',
			            padding: '0.25em',
			            textAlign: 'center',
			            textTransform: 'none'
			          }}
			        >
			          {data.title}
			        </h1>
			        <h1
			          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
			          style={{
			            color: 'white',
			            lineHeight: '1',
			            padding: '0.25em',
			            textAlign: 'center',
			            textTransform: 'none'
			          }}
			        >
			          {data.secondtitle}
			        </h1>
			        <p className="has-text-centered is-size-4"
			          style={{color: 'white', padding: "30px 10px"}}>
			          {data.description}
			        </p>
			        <div className="has-text-centered">
				        <a href={data.buttonlink} >
				          <button style={{fontSize: 20, marginTop: 20}}>{data.buttontxt}</button>
				        </a>
			        </div>
			      </div>
			    </div>
			)
		} else {
			return(
				<div
			    	className="full-width-image margin-top-0"
			      style={{
			        backgroundImage: `url(${
			          !!data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image
			        })`,
			        backgroundPosition: `center center`,
			        backgroundSize: 'cover',
			        backgroundAttachment: `fixed`,
			        height: '700px',
			      }}
			    >
			      <div
			        style={{
			          display: 'flex',
			          height: '150px',
			          lineHeight: '1',
			          justifyContent: 'space-around',
			          alignItems: 'left',
			          flexDirection: 'column',
			          width: "90%",
			        }}
			      >
			        <div className="has-text-centered">
			          <img
			            style={{width: 150}}
			            src={!!data.midimage.image.childImageSharp ? data.midimage.image.childImageSharp.fluid.src : data.midimage.image}
			            alt="midimage"
			          />
			        </div>
			        <div style={{height: 20, textAlign: `${data.sideimage.align}` }}>
			          <img
			            style={{width: 150, marginTop: "-80px" }}
			            src={!!data.sideimage.image.childImageSharp ? data.sideimage.image.childImageSharp.fluid.src : data.sideimage.image}
			            alt="sideimage"
			          />
			        </div>
			        <h1
			          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
			          style={{
			            color: '#53c5d1',
			            lineHeight: '1',
			            padding: '0.25em',
			            textAlign: 'center',
			            textTransform: 'uppercase'
			          }}
			        >
			          {data.title}
			        </h1>
			        <h1
			          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
			          style={{
			            color: 'white',
			            lineHeight: '1',
			            padding: '0.25em',
			            textAlign: 'center',
			            textTransform: 'uppercase'
			          }}
			        >
			          {data.secondtitle}
			        </h1>
			        <p className="has-text-centered is-size-4"
			          style={{color: 'white', padding: "30px 10px"}}>
			          {data.description}
			        </p>
			        <div className="has-text-centered">
			        	<a href={data.buttonlink} >
			          		<button style={{fontSize: 20, marginTop: 20}}>{data.buttontxt}</button>
			          	</a>
			        </div>
			      </div>
			    </div>
			)
		}
	}
}

export default Hero
