import React from 'react'
import PropTypes from 'prop-types'
import {
  graphql
} from 'gatsby'

import Layout from '../components/Layout'
import Hero from "../components/Hero"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import AboveFooter from "../components/AboveFooter"
import SEO from '../components/SEO'

export const IndexPageTemplate = ({
  hero,
  contactus,
  sectionlist,
  cardsection,
  fixedimagesection
}) => (
    <div >
      <SEO title={hero.title} description={hero.description} homepage={true} />
      <Hero data={
        hero
      }
      />

      <div style={
        {
          marginTop: -20
        }
      } > {
          sectionlist.list.map((item, i) => {
            if ((i % 2) === 0) {
              return (
                <div className="section"
                  style={
                    {
                      background: `${item.sectionbg}`
                    }
                  }
                  key={
                    i
                  } >
                  <div className="container" >
                    <div className="columns" >
                      <div className="column is-8" >
                        <h1 className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen"
                          style={
                            {
                              color: `${item.textcolor}`,
                              lineHeight: "1",
                              marginTop: "1rem"
                            }
                          } >
                          {
                            item.title
                          } < br /> {
                            item.secondtitle
                          } </h1> <p style={
                            {
                              color: `${item.textcolor}`,
                              margin: "2rem 0"
                            }
                          } >
                          {
                            item.description
                          }

                        </p> <a href={
                          item.buttonlink
                        } >
                          <button style={
                            {
                              marginBottom: "2rem",
                              background: `${item.buttonbg}`
                            }
                          } >
                            {
                              item.buttontxt
                            } </button> </a>
                      </div> <div className="column is-4" >
                        <PreviewCompatibleImage imageInfo={
                          item.image
                        }
                        /> </div>
                    </div>

                  </div> </div>
              )
            } else {
              return (
                <div className="section"
                  style={
                    {
                      background: `${item.sectionbg}`
                    }
                  }
                  key={
                    i
                  } >
                  <div className="container" >
                    <div className="columns" >
                      <div className="column is-4" >
                        <   PreviewCompatibleImage imageInfo={
                          item.image
                        }
                        /> </div> <div className="column is-8" >
                        <h1 className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen"
                          style={
                            {
                              color: `${item.textcolor}`,
                              lineHeight: "1",
                              marginTop: "1rem"
                            }
                          } >
                          {
                            item.title
                          } < br /> {
                            item.secondtitle
                          } </h1> <p style={
                            {
                              color: `${item.textcolor}`,
                              margin: "2rem 0"
                            }
                          } >
                          {
                            item.description
                          }

                        </p> <a href={
                          item.buttonlink
                        } >
                          <button style={
                            {
                              marginBottom: "2rem",
                              background: `${item.buttonbg}`
                            }
                          } >
                            {
                              item.buttontxt
                            } </button> </a> </div> </div> </div> </div>
              )
            }
          })
        } </div> <div className="section"
          style={
            {
              background: "#28363d",
              marginTop: "10em"
            }
          } >
        <div className="container" >
          <div className="columns"
            style={
              {
                marginTop: "-11em"
              }
            } > {
              cardsection.list.map((card, i) => {
                return (<div className="column is-3"
                  style={
                    {
                      padding: "0 1rem"
                    }
                  }
                  key={
                    i
                  } >
                  <PreviewCompatibleImage imageInfo={
                    card.image
                  }
                  /> <div style={
                    {
                      background: "white",
                      padding: "1rem"
                    }
                  } >
                    <h1 > {
                      card.title
                    } < br /> {
                        card.secondtitle
                      } </h1> <p style={
                        {
                          color: "#53c5d1",
                          margin: "1rem 0",
                          fontSize: "14px"
                        }
                      } > {
                        card.description
                      } </p> <a href={
                        card.buttonlink
                      } >
                      <button style={
                        {
                          background: "#53c5d1"
                        }
                      } > Learn More </button>
                    </a> </div> </div>
                )
              })
            } </div> </div> </div> <AboveFooter data={
              fixedimagesection
            }
              height="500px" />
    </div>
  )

IndexPageTemplate.propTypes = {
  hero: PropTypes.object,
  contactus: PropTypes.object,
  imageSection: PropTypes.object,
  sectionlist: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.array,
  }),
  cardsection: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.array
  }),
  fixedimagesection: PropTypes.object
}

const IndexPage = ({
  data
}) => {
  const {
    frontmatter
  } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate hero={
        frontmatter.hero
      }
        contactus={
          frontmatter.contactus
        }
        sectionlist={
          frontmatter.sectionlist
        }
        cardsection={
          frontmatter.cardsection
        }
        fixedimagesection={
          frontmatter.fixedimagesection
        }
      /> </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
                markdownRemark(frontmatter: {templateKey: {eq: "index-page" } }) {
                frontmatter {
                hero{
                title
          secondtitle
          image {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
                alt
            image {
                childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              }
            }
          }
          sideimage{
                alt
            align
            image {
                childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
                title
          buttontxt
          buttonlink
        }
        sectionlist {
                title
          list {
                title
            secondtitle
            sectionbg
            textcolor
            description
            buttontxt
            buttonbg
            buttonlink
            image {
                childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              }
            }
          }
        }
        cardsection {
                title
          list {
                image {
                childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              }
            }
            title
            secondtitle
            description
            buttonlink
          }
        }
        fixedimagesection {
                title
          secondtitle
          image {
                childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
